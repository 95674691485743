import React from 'react';
import { Helmet } from 'react-helmet-async';


const  SEO = ({title, name, type,canonical}) => {

    const description = "Jan Mędrala countertenor singer artist's website"
    const keywords = "Jan, Mędrala, kontratenor, wokalista, countertenor, vocal, jasiekemedrala, master, trener, coach"

return (
<Helmet>

<title>{title}</title>
<link rel="canonical" href={canonical} />
<meta name='description' content={description} />
<meta name="keywords" content={keywords}></meta>
<meta property="og:type" content={type} />
<meta property="og:title" content={title} />
<meta property="og:url" content={canonical} />
<meta property="og:description" content={description} />
<meta name="twitter:creator" content={name} />
<meta name="twitter:card" content={type} />
<meta name="twitter:title" content={title} />
<meta name="twitter:description" content={description} />

</Helmet>
)
}


export default SEO