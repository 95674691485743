
import  SEO  from "./SEO"



const Home = () => {
    return (




        <div className="home">

            <SEO
            title="Home of Modralova"
            description="starting Page"
            name="Modralova"
            type="article"
            canonical="https://modralova.com/"
            />
             
        </div>);
};





export default Home;
