import SCI from './images/soundCloudIcon.svg';

const SoundCloudIcon = () => {
    return ( 

  <img src={SCI} alt="SoundCloudIcon" style={{color:"blue"}} /> 

     );
}
 
export default SoundCloudIcon;